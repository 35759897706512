<template>
  <div class="glo-layout">
    <div class="glo-layout__body">
      <div class="glo-table-wrapper">
        <div class="glo-table-wrapper__header">
          <div class="glo-table-wrapper__search" style="text-align: right">
            <el-input v-model="searchName">
              <el-button
                type="primary"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div>
          <div class="glo-table-wrapper__form">
            <el-button
              @click="dialogTableVisible = true"
              type="primary"
              icon="el-icon-circle-plus-outline"
              size="small"
            >
              主要按钮
            </el-button>
            <el-form inline class="form-inline form-ui">
              <el-form-item label="状态">
                <el-select v-model="state" placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="编辑中" value="SAVED"></el-option>
                  <el-option label="已提交" value="COMMITTED"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="glo-table-wrapper__body">
          <el-table :data="tableData" stripe height="500px" style="width: 100%">
            <el-table-column
              prop="date"
              label="日期"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              width="180"
            ></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column label="状态" width="100">
              <template>
                <span class="approvalPASSED">核验通过</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template>
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="link"
                  size="small"
                ></el-button>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="glo-table-wrapper__footer">
          <el-pagination
            align="center"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          ></el-pagination>
        </div>
        <el-dialog
          title="收货地址"
          :visible.sync="dialogTableVisible"
          append-to-body
        >
          <el-table :data="tableData" height="300px" style="width: 100%">
            <el-table-column
              property="date"
              label="日期"
              width="150"
            ></el-table-column>
            <el-table-column
              property="name"
              label="姓名"
              width="200"
            ></el-table-column>
            <el-table-column property="address" label="地址"></el-table-column>
          </el-table>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
function mockData(start, end) {
  let cate = 1; // 类别
  const list = [];
  for (let i = 0; i < end; i++) {
    const text = getContent();
    const text2 = getContent();
    if (Math.random() > 0.6) cate++;

    list.push({
      id: start++,
      index: i,
      text,
      text2,
      show: false,
      checked: false,
      date: new Date().toLocaleString(),
      name: '王小虎',
      province: '上海',
      city: '普陀区',
      address: '上海市普陀区金沙江路 1518 弄',
      zip: 200333,
      category: '江浙小吃、小吃零食',
      desc: '荷兰优质淡奶，奶香浓而不腻',
      shop: '王小虎夫妻店',
      shopId: '10333',
      count: genRandomVal(0, 1000),
      cate,
    });
  }
  return list;
}

function genRandomVal(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

function getContent() {
  const content = [
    '若耶溪傍采莲女，笑隔荷花共人语。',
    '君不见黄河之水天上来，奔流到海不复回。',
    '十年生死两茫茫。',
    '寻寻觅觅，冷冷清清，凄凄惨惨戚戚。',
    '桃花坞里桃花庵，桃花庵里桃花仙；桃花仙人种桃树，又摘桃花卖酒钱。',
    '明月几时有，把酒问青天。',
    '槛菊愁烟兰泣露，罗幕轻寒。',
    '寒蝉凄切，对长亭晚，骤雨初歇。都门帐饮无绪，留恋处，兰舟催发。执手相看泪眼，竟无语凝噎。念去去，千里烟波，暮霭沉沉楚天阔。多情自古伤离别，更那堪冷落清秋节！今宵酒醒何处？杨柳岸，晓风残月。此去经年，应是良辰好景虚设。便纵有千种风情，更与何人说？',
    '红豆生南国，春来发几枝。',
    '烛影摇风，一枕伤春绪。',
  ];
  const i = Math.floor(Math.random() * 10);
  return content[i];
}
export default {
  data() {
    return {
      state: '',
      searchName: '',
      tableData: mockData(0, 150),
      dialogTableVisible: false,
      pagination: {
        count: 10,
        page: 1,
        pageSize: 20,
        searchName: '',
      },
    };
  },

  methods: {
    link() {
      this.$router.push('info')
    }
  },

  created() {},
};
</script>
<style>
.form-ui .el-form-item {
  margin-right: 20px !important;
}
.dialog-form .el-select {
  width: 100%;
}
.glo-container__body {
  height: 100%;
  padding: 10px 0 0;
  box-sizing: border-box;
  overflow: hidden;
}
.glo-layout {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.glo-layout__body {
  height: 100%;
  box-sizing: border-box;
}
.el-dialog .el-table {
  padding: 0 20px !important;
}
</style>
<style lang="scss">
@include b(table-wrapper) {
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  // padding: 10px;

  @include e(header) {
    @include clearBoth;
    padding: 10px 0;
    padding-top: 0;
    .countBox {
      float: left;
      margin-top: 12px;
    }
  }

  @include e(search) {
    float: right;
    text-align: right;
    // margin-right: 3px;
    // margin-left: 5px;
  }

  @include e(form) {
    overflow: hidden;

    & :deep(.el-form-item) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  @include e(body) {
    // height: calc(100% - 85px);

    // .el-table{
    //     height: 100%!important;
    //     min-height: 500px;
    // }
    & .el-button {
      margin-right: 3px;
    }

    & .el-button:last-child {
      margin-right: 0;
    }

    & :deep(.el-radio) {
      margin-right: 0;

      & .el-radio__label {
        display: none;
      }
    }

    & :deep(.el-table__body-wrapper) {
      -ms-overflow-style: scrollbar;
    }
  }

  @include e(footer) {
    // height: 40px;
    // line-height: 40px;
    padding-top: 5px;
    text-align: center;
    padding-bottom: 10px;
  }
}
.not-full-scroll {
  overflow-y: auto;
}
/* 整个滚动条 */
.not-full-scroll::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 6px;
  /* 对应横向滚动条的宽度 */
  height: 6px;
}
/* 滚动条上的滚动滑块 */
.not-full-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.3);
  border-radius: 6px;
}
/* 滚动条轨道 */
.not-full-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 6px;
}

@include b(pagination) {
  display: inline-block;
  padding: 0;
}
</style>
<style lang="scss">
.g-form.el-form--inline {
  .el-form-item {
    width: 100%;
  }
  .el-form-item__content {
    overflow: hidden;
    display: block;
  }
  .el-input-group {
    box-sizing: border-box;
  }
}
.approvalCOMMITTED,
.approvalAPPROVING,
.approvalABOLISH {
  width: auto;
  height: auto;
  padding: 6px 5px;
  font-size: 12px;
  border-radius: 1px;
  border: 1px solid;
  background-color: #e8f4ff;
  border-color: #1890ff;
  color: #1890ff;
}
.approvalPASSED,
.approvalREPLY {
  width: auto;
  height: auto;
  padding: 6px 5px;
  font-size: 12px;
  border-radius: 1px;
  border: 1px solid;
  background-color: #eef7e8;
  border-color: #57b21c;
  color: #57b21c;
}
.approvalNOPASSED,
.approvalRETURNED,
.approvalRETURNBACK {
  width: auto;
  height: auto;
  padding: 6px 5px;
  font-size: 12px;
  border-radius: 1px;
  border: 1px solid;
  background-color: #fdeaea;
  border-color: #e82f2f;
  color: #e82f2f;
}
.approvalSAVED {
  width: auto;
  height: auto;
  padding: 6px 5px;
  font-size: 12px;
  border-radius: 1px;
  border: 1px solid;
  background-color: #fff4e6;
  border-color: #fc9306;
  color: #fc9306;
}
.selectArea {
  .cell {
    padding: 0 !important;
    label {
      width: 100%;
    }
    .is-disabled {
      .el-checkbox__inner::before {
        box-sizing: content-box;
        content: '';
        border: 1px solid #666;
        border-left: 0;
        border-top: 0;
        height: 7px;
        left: 4px;
        position: absolute;
        top: 1px;
        transform: rotate(45deg) scaleY(1);
        width: 3px;
        transition: transform 0.15s ease-in 0.05s;
        transform-origin: center;
      }
    }
  }
}
.disabledRow.el-table__row {
  td {
    .cell {
      color: #888;
    }
  }
}
.disabledAll.selectArea {
  .el-checkbox__inner {
    background-color: #efefef;
    border-color: #999;
    cursor: not-allowed;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}
.is-numberAlignment {
  text-align: right;
  display: inherit;
}
</style>
