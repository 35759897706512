<template>
  <div class="glo-layout glo-layout--fixed">
    <div class="glo-page-header">
      <el-page-header @back="back" content="详情页面"></el-page-header>
    </div>
    <div class="glo-layout__body">
      <div
        class="glo-layout__body--scroll"
        style="margin-right: -25px; padding-right: 17px"
      >
        <el-collapse default-expand-all icon-text v-model="activeNames">
          <el-collapse-item title="项目信息" name="1">
            <el-form
              :model="ruleForm"
              :rules="rules"
              :grid="true"
              :gutter="20"
              ref="ruleForm"
              label-width="auto"
              class="glo-template__field"
            >
              <el-form-item
                :span="24"
                label="是否从投资在线审批平台获取项目"
                prop="resource"
              >
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
                <span style="margin-left: 20px">
                  <el-button type="primary">复制项目信息</el-button>
                  <el-button>清空项目信息</el-button>
                </span>
              </el-form-item>
              <el-form-item :span="24" label="项目名称" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请选填写名称"
                ></el-input>
              </el-form-item>
              <el-form-item :span="24" label="项目编号" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请选填写名称"
                ></el-input>
              </el-form-item>
              <el-form-item :span="12" label="项目类别" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="项目行业分类" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="24" label="项目行业分类" prop="region">
                <el-row :gutter="10" style="margin: 0">
                  <el-col :span="4" style="padding-left: 0">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12" style="padding-right: 0">
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="请选填写名称"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item :span="12" label="招标组织形式" prop="date1">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="投资项目统一代码">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请选填写名称"
                ></el-input>
              </el-form-item>
              <el-form-item :span="24" label="项目法人" prop="date1">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请选填写名称"
                ></el-input>
              </el-form-item>
              <el-form-item :span="12" label="资金来源" prop="date1">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="投资总额" prop="date1">
                <el-input placeholder="请输入内容" v-model="ruleForm.name">
                  <el-select
                    class="g3-width100"
                    v-model="ruleForm.region"
                    slot="append"
                    placeholder="请选择"
                  >
                    <el-option label="餐厅名" value="1"></el-option>
                    <el-option label="订单号" value="2"></el-option>
                    <el-option label="用户电话" value="3"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item :span="24" label="出资比例">
                <el-row>
                  <el-col :span="12">
                    <el-input placeholder="请输入内容" v-model="ruleForm.name">
                      <el-select
                        class="g3-width100"
                        v-model="ruleForm.region"
                        slot="append"
                        placeholder="请选择"
                      >
                        <el-option label="餐厅名" value="1"></el-option>
                        <el-option label="订单号" value="2"></el-option>
                        <el-option label="用户电话" value="3"></el-option>
                      </el-select>
                    </el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-input placeholder="请输入内容" v-model="ruleForm.name">
                      <el-select
                        class="g3-width100"
                        v-model="ruleForm.region"
                        slot="append"
                        placeholder="请选择"
                      >
                        <el-option label="餐厅名" value="1"></el-option>
                        <el-option label="订单号" value="2"></el-option>
                        <el-option label="用户电话" value="3"></el-option>
                      </el-select>
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item :span="24" label="项目规模" prop="desc">
                <el-input type="textarea" v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="12" label="联系人" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="12" label="联系方式" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" label="项目审批文件名称">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="12" label="项目审批文号" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="12" label="项目审批单位" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" label="项目行业分类" prop="region">
                <el-row :gutter="10" style="margin: 0">
                  <el-col :span="8" style="padding-left: 0">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </el-collapse-item>
          <el-collapse-item title="招标项目概况" name="2">
            <el-form
              :model="ruleForm"
              :rules="rules"
              :grid="true"
              :gutter="20"
              ref="ruleForm"
              label-width="auto"
              class="glo-template__field"
            >
              <el-form-item :span="24" label="招标项目编号">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请选填写名称"
                ></el-input>
              </el-form-item>
              <el-form-item :span="24" label="招标项目名称" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请选填写名称"
                ></el-input>
              </el-form-item>
              <el-form-item :span="12" label="招标项目类型" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="交易发生区域" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :span="24"
                label="招标内容与范围及招标方案说明"
                prop="desc"
              >
                <el-input type="textarea" v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" label="项目业主名称" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="12" label="招标方式" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="资审方式" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="招标组织形式" prop="region">
                <el-select
                  v-model="ruleForm.region"
                  placeholder="请选择活动区域"
                >
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :span="12" label="招标项目建立时间" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" prop="desc">
                <span slot="label">
                  监督部门名称
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="公告公示中的监管部门同此处内容"
                    placement="top-start"
                  >
                    <i class="el-icon-question-outline"></i>
                  </el-tooltip>
                </span>
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" label="监督部门代码">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" label="审核部门名称" prop="desc">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="12" label="审核部门代码">
                <el-input v-model="ruleForm.desc"></el-input>
              </el-form-item>
              <el-form-item :span="24" label="评标方式" prop="region">
                <el-row :gutter="10" style="margin: 0">
                  <el-col :span="12" style="padding-left: 0">
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <span>
                      <i class="el-icon-warning" style="color: #fc9306"></i>
                      请选择与招标文件范本一致的评标方式，否则将影响后续流程
                    </span>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item :span="12" label="是否为电子标" prop="resource">
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :span="12" label="是否远程异地评标" prop="resource">
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :span="12" label="是否划分标段" prop="resource">
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                :span="12"
                label="是否对外发布控制价文件"
                prop="resource"
              >
                <el-radio-group v-model="ruleForm.resource">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-collapse-item>
          <el-collapse-item title="招标人信息" name="3">
            <div class="glo-table-wrapper">
              <div class="glo-table-wrapper__header">
                <div class="glo-table-wrapper__form">
                  <el-button type="primary">主要按钮</el-button>
                  <span>
                    <i class="el-icon-warning" style="color: #fc9306"></i>
                    此列表至少有一条信息
                  </span>
                </div>
              </div>
              <div class="glo-table-wrapper__body">
                <el-table
                  :data="tableData"
                  isdrag
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    prop="date"
                    label="日期"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    prop="name"
                    label="姓名"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    prop="address"
                    label="地址"
                  ></el-table-column>
                  <el-empty slot="empty" description="暂无数据"></el-empty>
                </el-table>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="glo-layout__footer">
      <el-button type="primary" plain>主要按钮</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      activeNames: [],
      restaurants: [],
      timeout: null,
      ruleForm: {
        name: '',
        search: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        range: [],
        type: [],
        resource: '',
        desc: '',
        tree: '',
      },
      treeData: [
        {
          label: '一级 1',
          value: '1',
          children: [
            {
              label: '二级 1-1',
              value: '1-1',
              children: [
                {
                  label:
                    '特别长的文字特别长的文字特别长的文字特别长的文字特别长的文字特别长的文字',
                  value: '1-1-1',
                },
              ],
            },
          ],
        },
        {
          label: '一级 2',
          value: '2',
          children: [
            {
              label: '二级 2-1',
              value: '2-1',
              children: [
                {
                  label: '三级 2-1-1',
                  value: '2-1-1',
                },
              ],
            },
            {
              label: '二级 2-2',
              value: '2-2',
              children: [
                {
                  label: '三级 2-2-1',
                  value: '2-2-1',
                },
              ],
            },
          ],
        },
        {
          label: '一级 3',
          value: '3',
          children: [
            {
              label: '二级 3-1',
              value: '3-1',
              children: [
                {
                  label: '三级 3-1-1',
                  value: '3-1-1',
                },
              ],
            },
            {
              label: '二级 3-2',
              value: '3-2',
              children: [
                {
                  label: '三级 3-2-1',
                  value: '3-2-1',
                },
              ],
            },
          ],
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          {
            min: 3,
            max: 5,
            message: '长度在 3 到 5 个字符长度在 3 到 5 个字符',
            trigger: 'blur',
          },
        ],
        search: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { required: true, message: '请输入活动名称', trigger: 'change' },
        ],
        range: [{ required: true, message: '请输入金额区间', trigger: 'blur' }],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' },
        ],
        tree: [
          { required: true, message: '请选择活动区域', trigger: 'change' },
        ],
        date1: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
        date2: [
          {
            type: 'date',
            required: true,
            message: '请选择时间',
            trigger: 'change',
          },
        ],
        type: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个活动性质',
            trigger: 'change',
          },
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' },
        ],
        desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    back() {
      this.$router.push('/')
    }
  },
};
</script>
<style>
.glo-layout--fixed {
  margin-bottom: -54px;
  padding-bottom: 54px;
}
.glo-page-header {
  overflow: hidden;
  width: 100%;
  height: 46px;
  line-height: 46px;
  padding: 0 10px;
  margin-top: -10px;
  font-size: 14px;
  box-sizing: border-box;
  color: #333;
  background: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}
.glo-page-header + .glo-layout__body {
  margin-top: -36px;
  padding-top: 46px;
}
.glo-layout__body--scroll {
  height: 100%;
  padding: 0 10px;
  padding-right: 10px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
.glo-template__field .el-form-item__content .el-select {
  width: 100%;
}
</style>
