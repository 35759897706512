import Home from '../views/Home.vue';
import Info from '../views/Info.vue';

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      key: 'Home',
      title: 'Home',
    },
  },
  {
    path: '/info',
    name: 'Info',
    component: Info,
    meta: {
      key: 'info',
      title: 'info',
    },
  },
  {
    path: '*',
    redirect: '/home',
    component: Home,
  },
];

export default routes;
