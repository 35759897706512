import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import routes from './router'
import store from './store'
import ElementUI from 'element-gui';
import './assets/iconfont/iconfont.css'
import "element-gui/lib/theme-chalk/index.css";
import "./assets/scss/index.scss";
import { setHeaders, MicroAppStore } from '@geip/portal-utils/lib/microApp'
Vue.use(ElementUI, { content: 'content2' });
Vue.use(VueRouter)

Vue.config.productionTip = false
let router = null
let instance = null;
const rootState = {
  orgId: '',
  refresh: false
}

function render(props = {}) {
  const { container, baseUrl } = props
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? `${baseUrl}` : `/`,
    mode: 'history',
    routes
  })

  instance = new Vue({
    router,
    store,
    data () {
      return rootState
    },
    render: h => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app');
}

if (window.__POWERED_BY_QIANKUN__) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
} else {
  render();
}

function initStore (props) {
  const options = { selfRefresh: true }
  const store = new MicroAppStore(props, options)
  store.toBindStateChange({ rootState, setHeaders })
}

export async function bootstrap(props) {
  console.log(props)
}

export async function mount(props) {
  initStore(props)
  render(props);
}
export async function unmount(props) {
  console.log(props)
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
}
